import './BurgerMenu.scss';

interface MenuProps {
  open: boolean;
  onClose: () => void;
}

const BurgerMenu = (props: MenuProps) => {
  const { open, onClose } = props;

  return (
    <div
      role="button"
      tabIndex={0}
      className={`hamburger ${open ? 'is-active' : ''}`}
      onClick={onClose}
      onKeyDown={onClose}
      aria-label="menu"
    >
      <span className="line" />
      <span className="line" />
      <span className="line" />
    </div>
  );
};

export default BurgerMenu;
