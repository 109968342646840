import {
  FC,
  useCallback,
  useState,
  useEffect,
  JSX,
} from 'react';
import { Link } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  Collapse,
  Box,
  ListItemText,
} from '@mui/material';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';

import './Links.scss';

export interface ILink {
  name: string;
  icon: JSX.Element;
  path?: string;
  key: string;
  children?: Omit<ILink, 'children'>[];
}

interface IComponentProps {
  links: ILink[];
  pathName: string;
}

const Links: FC<IComponentProps> = ({ links, pathName }) => {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [selectedParents, setSelectedParents] = useState<string[]>([]);

  const handleClick = useCallback((item: ILink) => {
    if (expandedItems.includes(item.key)) {
      setExpandedItems(expandedItems.filter((c) => c !== item.key));
    } else if (!item.path) {
      setExpandedItems([...expandedItems, item.key]);
    }
  }, [expandedItems, pathName]);

  const getExpandedItems = useCallback((
    links: ILink[],
    parent?: string,
    expandedList: string[] = [],
    parentsList: string[] = [],
  ) => {
    links.forEach((item) => {
      if (Array.isArray(item.children)) {
        getExpandedItems(item.children, item.key, expandedList, parentsList);
      }
      if (item.path && pathName.includes(item.path) && parent) {
        expandedList.push(parent);
        parentsList.push(parent);
      }
    });
    return { expandedList, parentsList };
  }, [pathName]);

  useEffect(() => {
    const { expandedList, parentsList } = getExpandedItems(links);
    setExpandedItems(Array.from(new Set([...expandedList])));
    setSelectedParents(Array.from(new Set([...parentsList])));
  }, [links, getExpandedItems]);

  const renderLinks = (links: ILink[]) => (
    links.map((item) => {
      const isExpanded = expandedItems.includes(item.key);
      const isSelectedParent = selectedParents.includes(item.key);
      const isSelectedUserView = selectedParents[0] === 'userViews';
      return (
        <Box
          key={item.name}
          className={classNames(
            'links__item',
            { expanded: isExpanded },
            { 'views-group': isSelectedUserView },
            { 'admin-group': !isSelectedUserView },
            { selected: item.path && pathName.includes(item.path) },
            { 'selected-parent': isSelectedParent },
          )}
        >
          <ListItem
            key={item.key}
            disablePadding
          >
            <ListItemButton
              component={item.path ? Link : Box}
              to={item.path ?? ''}
              onClick={() => handleClick(item)}
            >
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText>
                {item.name}
                {isExpanded && (
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="expand-icon arrow-up"
                    />
                  </ListItemIcon>
                )}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit className="links__nested">
            {Array.isArray(item.children) ? renderLinks(item.children) : null}
          </Collapse>
        </Box>
      );
    })
  );

  return (
    <List className="links">
      {renderLinks(links)}
    </List>
  );
};

export default Links;
