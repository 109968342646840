import { useLocation } from 'react-router-dom';
import { Drawer } from '@mui/material';

import Links from './Links/Links';
import pagesLinks from './PagesLinks';

import { ReactComponent as Logo } from '../../../assets/logo.svg';

import './NavigationPane.scss';

const NavigationPane = () => {
  const { pathname } = useLocation();

  return (
    <Drawer
      className="navigation-pane"
      variant="permanent"
      anchor="left"
      id="navigation-pane"
    >
      <div className="nav-wrapper">
        <Logo />
        <Links links={pagesLinks()} pathName={pathname} />
      </div>
    </Drawer>
  );
};

export default NavigationPane;
