import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IFilterCount } from 'components/shared/AppTable/components/TableFilters/TableFilters';
import { IView, IViewDetail, IViewsResponse } from 'interfaces/IView';

export interface IViewsState {
  views: IView[];
  userViews: IView[];
  totalCount: number | null;
  userTotalCount: number | null;
  filters: IFilterCount | null;
  userFilters: IFilterCount | null;
  isViewsLoading: boolean;
  triggerViews: object;
  detailViews: IViewsResponse | null;
  editViews: IViewsResponse | null;
  viewDetail: IViewDetail | null;
  editViewData: IViewDetail | null;
  detailsViewData: IViewDetail | null;
  favoriteViews: IView[];
  isShowFormatterModal: boolean;
  viewData: IView[];
  isViewDataLoading: boolean;
  totalCountViewItems: number | null;
  viewDataDetails: any;
}

export const initialState: IViewsState = {
  views: [],
  userViews: [],
  totalCount: null,
  userTotalCount: null,
  filters: null,
  userFilters: null,
  isViewsLoading: false,
  triggerViews: {},
  detailViews: null,
  editViews: null,
  viewDetail: null,
  favoriteViews: [],
  isShowFormatterModal: false,
  viewData: [],
  editViewData: null,
  detailsViewData: null,
  viewDataDetails: null,
  isViewDataLoading: false,
  totalCountViewItems: null,
};

export const viewsSlice = createSlice({
  name: 'views',
  initialState,
  reducers: {
    setViews(state, action: PayloadAction<IView[]>) {
      state.views = action.payload;
    },
    setUserViews(state, action: PayloadAction<IView[]>) {
      state.userViews = action.payload;
    },
    setTotalCount(state, action: PayloadAction<null | number>) {
      state.totalCount = action.payload;
    },
    setUserTotalCount(state, action: PayloadAction<null | number>) {
      state.userTotalCount = action.payload;
    },
    setFilters(state, action: PayloadAction<IFilterCount | null>) {
      state.filters = action.payload;
    },
    setUserFilters(state, action: PayloadAction<IFilterCount | null>) {
      state.userFilters = action.payload;
    },
    setIsViewsLoading(state, action: PayloadAction<boolean>) {
      state.isViewsLoading = action.payload;
    },
    setDetailViews(state, action: PayloadAction<IViewsResponse | null>) {
      state.detailViews = action.payload;
    },
    setEditViews(state, action: PayloadAction<IViewsResponse | null>) {
      state.editViews = action.payload;
    },
    setViewDetail(state, action: PayloadAction<IViewDetail | null>) {
      state.viewDetail = action.payload;
    },
    setEditViewData(state, action: PayloadAction<IViewDetail | null>) {
      state.editViewData = action.payload;
    },
    setDetailsViewData(state, action: PayloadAction<IViewDetail | null>) {
      state.detailsViewData = action.payload;
    },
    setIsShowFormatterModal(state, action: PayloadAction<boolean>) {
      state.isShowFormatterModal = action.payload;
    },
    setTriggerViews(state) {
      state.triggerViews = {};
    },
    setFavoriteViews(state, action: PayloadAction<IView[]>) {
      state.favoriteViews = action.payload;
    },
    setViewData(state, action: PayloadAction<IView[]>) {
      state.viewData = action.payload;
    },
    setViewDataDetails(state, action: PayloadAction<any>) {
      state.viewDataDetails = action.payload;
    },
    setIsViewDataLoading(state, action: PayloadAction<boolean>) {
      state.isViewDataLoading = action.payload;
    },
    setTotalCountViewItems(state, action: PayloadAction<null | number>) {
      state.totalCountViewItems = action.payload;
    },
    resetViewsState: () => initialState,
  },
});

export const {
  setTotalCount,
  setFilters,
  setViews,
  setUserViews,
  setIsViewsLoading,
  setTriggerViews,
  setDetailViews,
  setEditViews,
  setViewDetail,
  setFavoriteViews,
  setIsShowFormatterModal,
  setViewData,
  setIsViewDataLoading,
  setTotalCountViewItems,
  setUserTotalCount,
  setUserFilters,
  setViewDataDetails,
  setDetailsViewData,
  setEditViewData,
  resetViewsState,
} = viewsSlice.actions;

export default viewsSlice.reducer;
