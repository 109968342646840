import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import moment from 'moment-timezone';

import AppRouter from 'components/layouts/AppRouter/AppRouter';
import NavigationPane from 'components/shared/NavigationPane/NavigationPane';
import AppHeader from 'components/shared/AppHeader/AppHeader';
import { AppLoader } from 'components/shared/AppLoader/AppLoader';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { ErrorMessage } from 'components/shared/ErrorMessage/ErrorMessage';
import { useTypedDispatch } from 'hooks/useTypedDispatch';
import { isAppLoaderelector } from 'store/app/appSelectors';
import { initApp } from 'store/app/appThunk';
import { Token } from 'enum/Token';
import {
  accountSelector, isAuthSelector, serviceSelector,
  userSelector,
} from 'store/auth/authSelectors';
import { RoutePath } from 'constants/RoutePath';
import { setIsNavDisabled } from 'store/app/appReducer';
import { saveVisitedPage } from 'utils/saveVisitedPage';

import './App.scss';

const App = () => {
  const { i18n } = useTranslation();
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const isAppLoader = useSelector(isAppLoaderelector);
  const isAuth = useSelector(isAuthSelector);
  const serviceData = useSelector(serviceSelector);
  const accountData = useSelector(accountSelector);
  const user = useSelector(userSelector);

  const LOCALE = i18n.language?.toLowerCase();

  useEffect(() => {
    // by default, momentjs comes with en-us
    if (LOCALE !== 'en-us') {
      import(`moment/locale/${LOCALE}`)
        .then(() => {
          moment.locale(LOCALE);
        }).catch((err) => {
          console.error(err);
        });
    }
  }, []);

  useEffect(() => {
    dispatch(setIsNavDisabled(pathname === RoutePath.HOME));
  }, [pathname]);

  useEffect(() => {
    dispatch(setIsNavDisabled(!serviceData || !accountData));
  }, [serviceData, accountData]);

  useEffect(() => {
    const access_token = searchParams?.get('access_token');
    const refresh_token = searchParams?.get('refresh_token');

    if (access_token && refresh_token) {
      localStorage.setItem(Token.ACCESS_TOKEN, access_token);
      localStorage.setItem(Token.REFRESH_TOKEN, refresh_token);

      setSearchParams(undefined);
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(initApp());
  }, []);

  useEffect(() => {
    const parts = pathname.split('/');
    const account = parts[1];
    const service = parts[2];
    if (
      user && serviceData && accountData
      && account === accountData.shortName
      && service === serviceData.shortName
    ) {
      saveVisitedPage(window.location.pathname, user.id, accountData.id, serviceData.id);
    }
  }, [pathname, user, serviceData, accountData]);

  if (isAppLoader || !isAuth) {
    return <AppLoader />;
  }

  return (
    <Box className="app" data-testid="app-component">
      <ConfirmModal />
      <NavigationPane />
      <ErrorMessage />
      <Box className="app__main">
        <AppHeader />
        <Box className="content">
          <AppRouter />
        </Box>
      </Box>
    </Box>
  );
};

export default App;
