export enum RoutePath {
  HOME = '/',
  BASE_ROUTE = '/:account/:service',
  ERROR = '/error',
  OBJECT_MANAGER = '/object-manager',
  ACCOUNT = '/account',
  TRANSFORMERS = '/transformers',
  ADD_TRANSFORMER = '/transformers/add-transformer',
  EDIT_TRANSFORMER = '/transformers/edit-transformer',
  DUPLICATE_TRANSFORMER = '/transformers/duplicate-transformer',
  PLUG_INS = '/plug-ins',
  PLUG_INS_STORE = '/plug-ins/add-plugin',
  ADD_OBJECT = '/object-manager/add-object',
  EDIT_OBJECT = '/object-manager/edit-object',
  DUPLICATE_OBJECT = '/object-manager/duplicate-object',
  VIEWS = '/views-admin',
  ADD_VIEW = '/views-admin/add-view',
  EDIT_VIEW = '/views-admin/edit-view',
  DUPLICATE_VIEW = '/views-admin/duplicate-view',
  TRIGGERS = '/triggers',
  ADD_TRIGGER = '/triggers/add-trigger',
  EDIT_TRIGGER = '/triggers/edit-trigger',
  USER_VIEWS = '/views-user',
  EDIT_USER_VIEW = '/views-user/edit-view',
  DUPLICATE_USER_VIEW = '/views-user/duplicate-view',
  VIEW = '/view',
  VIEW_EDIT = '/view-edit',
  VIEW_DETAILS = '/view-details',
  ACTIONS = '/actions',
  ADD_ACTION = '/actions/add-action',
  EDIT_ACTION = '/actions/edit-action',
  FLOWS = '/flows',
  ADD_FLOW = '/flows/add-flow',
  EDIT_FLOW = '/flows/edit-flow',
  ADD_ENTITY = '/create-object',
}
