import axios from 'axios';

import { requestInterceptors } from '../interceptors/requestInterceptors';
import { responseInterceptors } from '../interceptors/responseInterceptors';

export const apiInstance = axios.create({
  baseURL: '/api/atomyx_manage',
});

requestInterceptors(apiInstance);

responseInterceptors(apiInstance);

export default apiInstance;
