import { RootStateType } from '../store';

export const viewsSelector = (state: RootStateType) => {
  return state.viewsReducer.views;
};

export const userViewsSelector = (state: RootStateType) => {
  return state.viewsReducer.userViews;
};

export const totalCountSelector = (state: RootStateType) => {
  return state.viewsReducer.totalCount;
};

export const userTotalCountSelector = (state: RootStateType) => {
  return state.viewsReducer.userTotalCount;
};

export const filtersSelector = (state: RootStateType) => {
  return state.viewsReducer.filters;
};

export const userFiltersSelector = (state: RootStateType) => {
  return state.viewsReducer.userFilters;
};

export const isViewsLoadingSelector = (state: RootStateType) => {
  return state.viewsReducer.isViewsLoading;
};

export const triggerViewsSelector = (state: RootStateType) => {
  return state.viewsReducer.triggerViews;
};

export const detailViewsSelector = (state: RootStateType) => {
  return state.viewsReducer.detailViews;
};

export const editViewsSelector = (state: RootStateType) => {
  return state.viewsReducer.editViews;
};

export const viewDetailSelector = (state: RootStateType) => {
  return state.viewsReducer.viewDetail;
};

export const favoritesViewsSelector = (state: RootStateType) => {
  return state.viewsReducer.favoriteViews;
};

export const isShowFormatterModalSelector = (state: RootStateType) => {
  return state.viewsReducer.isShowFormatterModal;
};

export const viewDataSelector = (state: RootStateType) => {
  return state.viewsReducer.viewData;
};

export const isViewDataLoadingSelector = (state: RootStateType) => {
  return state.viewsReducer.isViewDataLoading;
};

export const totalCountViewItemsSelector = (state: RootStateType) => {
  return state.viewsReducer.totalCountViewItems;
};

export const viewDataDetailSelector = (state: RootStateType) => {
  return state.viewsReducer.viewDataDetails;
};

export const editViewDataSelector = (state: RootStateType) => {
  return state.viewsReducer.editViewData;
};

export const detailsViewDataSelector = (state: RootStateType) => {
  return state.viewsReducer.detailsViewData;
};
