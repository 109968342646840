import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-solid-svg-icons';

import AppModal from 'components/shared/AppModal/AppModal';
import { useTypedDispatch } from 'hooks/useTypedDispatch';
import { isShowPlugInModal } from 'store/plugin/pluginSelectors';
import { setIsShowPlugInModal, setActivePlugIn } from 'store/plugin/pluginReducer';
import AppIcon from 'components/shared/AppIcon/AppIcon';
import { getPlugInIconPath } from 'utils/getPlugInIconPath';
import { MAXRATING } from '../AddPlugInsPage/PlugInCard/PlugInCard';
import { RoutePath } from '../../../../constants/RoutePath';
import { dateBySlash } from '../../../../utils/dateBySlash';
import { deletePlugIn } from '../../../../store/plugin/pluginThunk';
import { IPlugInStore } from '../../../../interfaces/IPlugIn';
import { callConfirmModal } from '../../../../utils/callConfirmModal';

import './PluginModalDescription.scss';

const PlugInModalDescription: FC<IPlugInStore> = (
  {
    _id, author, version, name, label, description,
    allowMultipleInstances, installed, creationDate, modificationDate,
    rating, preferences, numOfInstances, icon,
  },
) => {
  const { t } = useTranslation('plugIn');
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const isShowModal = useSelector(isShowPlugInModal);

  const onCancel = useCallback(() => {
    dispatch(setIsShowPlugInModal({ isActive: false, _id: '' }));
  }, []);

  const handleInstall = useCallback(() => {
    if (installed) {
      callConfirmModal(
        {
          title: t('plugIn:confirmUninstall', { label }),
          onAgree() {
            dispatch(deletePlugIn(name, version));
            dispatch(setIsShowPlugInModal({ isActive: false, _id: '' }));
          },
          okText: t('plugIn:buttons.unInstallPlugIn'),
          cancelText: t('translation:buttons.cancel'),
        },
        dispatch,
      );
    } else {
      dispatch(setActivePlugIn({
        _id,
        author,
        version,
        name,
        label,
        description,
        allowMultipleInstances,
        installed,
        creationDate,
        modificationDate,
        preferences,
        rating,
        numOfInstances,
      }));
      navigate(`../..${RoutePath.PLUG_INS}/${name}/${version}`, { relative: 'path' });
    }
  }, [_id, author, version, name, label, description, allowMultipleInstances, installed,
    creationDate, modificationDate, preferences, rating]);

  const installedMessage = useMemo(() => {
    if (allowMultipleInstances && installed) {
      return numOfInstances === 1 ? t('installedOneTime', { numOfInstances }) : t('installedTimes', { numOfInstances });
    }
    return installed ? t('statusInstalled') : t('statusNotInstalled');
  }, [allowMultipleInstances, numOfInstances, installed]);

  const unInstalledMessage = useMemo(() => {
    if (installed) {
      return numOfInstances > 1 ? t('buttons.unInstallAllPlugIn') : t('buttons.unInstallPlugIn');
    }
    return t('buttons.installPlugIn');
  }, [numOfInstances, installed]);

  return (
    <AppModal
      open={isShowModal.isActive && _id === isShowModal._id}
      titleSubmitButton={unInstalledMessage}
      onCancel={onCancel}
      onSubmit={handleInstall}
      propsSubmitButton={{
        type: 'submit',
      }}
    >
      <div className="plugin-modal-description">
        <div className="plugin-modal-description__main-info">
          <div className="plugin-modal-description__main-info__header">
            <AppIcon path={icon ? getPlugInIconPath(name, version, installed) : ''} alt={label} className="plug-in-card-logo" />
            <div>
              <div className="plugin-modal-description__main-info__name">{label}</div>
              {rating && (
                <div className="plug-in-rating">
                  <FontAwesomeIcon icon={faStar} />
                  {`${rating}/${MAXRATING}`}
                </div>
              )}
            </div>
          </div>
          <div>
            <b className="description-label">{t('description')}</b>
            <div>{description}</div>
          </div>
        </div>
        <div className="plugin-modal-card">
          <div>
            <b>
              {`${t('version')}: `}
            </b>
            <span>{version}</span>
          </div>
          {/* hide this part for the demo
          <div>
            <b>
              {`${t('createdBy')}: `}
            </b>
            <span>{author}</span>
          </div>
          */}
          <div>
            <b>
              {`${t('lastUpdated')}: `}
            </b>
            <span>{dateBySlash(modificationDate)}</span>
          </div>
          <div>
            <b>
              {`${t('status')}: `}
            </b>
            <span>
              {installedMessage}
            </span>
          </div>
        </div>
      </div>
    </AppModal>
  );
};

export default PlugInModalDescription;
