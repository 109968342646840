import { RootStateType } from '../store';

export const userSelector = (state: RootStateType) => {
  return state.authReducer.user;
};

export const isAuthSelector = (state: RootStateType) => {
  return state.authReducer.isAuth;
};

export const avatarSelector = (state: RootStateType) => {
  return state.authReducer.avatar;
};

export const servicesSelector = (state: RootStateType) => {
  return state.authReducer.services;
};

export const serviceSelector = (state: RootStateType) => {
  return state.authReducer.service;
};

export const accountSelector = (state: RootStateType) => {
  return state.authReducer.account;
};

export const isNotFountSelector = (state: RootStateType) => {
  return state.authReducer.isNotFound;
};

export const isServiceAdminSelector = (state: RootStateType) => {
  return state.authReducer.isServiceAdmin;
};
