import { Avatar } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { toDataURL } from 'utils/getBase64Image';

interface IComponentProps {
  path: string;
}

const AppAvatar: FC<IComponentProps> = ({
  path,
}) => {
  const [avatar, setAvatar] = useState<string | undefined>();

  useEffect(() => {
    if (path) {
      toDataURL(path, setAvatar);
    } else {
      setAvatar('');
    }
  }, [path]);

  return <Avatar src={avatar} />;
};

export default AppAvatar;
