import CircularProgress from '@mui/material/CircularProgress';

import './AppLoader.scss';

export const AppLoader = () => {
  return (
    <div className="app-loader" data-testid="app-loader">
      <CircularProgress size="7rem" />
    </div>
  );
};
