import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFilterCount } from 'components/shared/AppTable/components/TableFilters/TableFilters';
import { ITransformer, ITransformerDetail } from 'interfaces/ITransformer';

export interface ITransformerState {
  transformers: ITransformer[];
  filters: IFilterCount | null;
  totalCount: number | null;
  isTransformersLoading: boolean;
  isShowTransformerModal: boolean;
  triggerTransformers: object;
  transformerDetail: ITransformerDetail | null;
}

export const initialState: ITransformerState = {
  transformers: [],
  filters: null,
  totalCount: null,
  isTransformersLoading: false,
  isShowTransformerModal: false,
  triggerTransformers: {},
  transformerDetail: null,
};

export const transformerSlice = createSlice({
  name: 'transformer',
  initialState,
  reducers: {
    setTransformers(state, action: PayloadAction<ITransformer[]>) {
      state.transformers = action.payload;
    },
    setFilters(state, action: PayloadAction<IFilterCount | null>) {
      state.filters = action.payload;
    },
    setTotalCount(state, action: PayloadAction<null | number>) {
      state.totalCount = action.payload;
    },
    setIsTransformersLoading(state, action: PayloadAction<boolean>) {
      state.isTransformersLoading = action.payload;
    },
    setIsShowTransformerModal(state, action: PayloadAction<boolean>) {
      state.isShowTransformerModal = action.payload;
    },
    setTransfromerDetail(state, action: PayloadAction<ITransformerDetail | null>) {
      state.transformerDetail = action.payload;
    },
    setTriggerTransformers(state) {
      state.triggerTransformers = {};
    },
    resetTransformersState: () => initialState,
  },
});

export const {
  setIsShowTransformerModal,
  setTransformers,
  setFilters,
  setIsTransformersLoading,
  setTriggerTransformers,
  setTotalCount,
  setTransfromerDetail,
  resetTransformersState,
} = transformerSlice.actions;

export default transformerSlice.reducer;
