import axios from 'axios';
import { Token } from 'enum/Token';
import { isProduction } from 'utils/isProd';
import { IUser } from 'interfaces/IUser';
import authApiInstance from './instances/authApiInstance';

const KEYCLOAK_CLIENT = process.env.REACT_APP_KEYCLOAK_CLIENT || '';
export const LOGIN_URL = `${isProduction() ? process.env.REACT_APP_VAULT : 'http://localhost:8443'}/login?redirect_url=`;
export const VAULT_URL = isProduction() ? process.env.REACT_APP_VAULT : 'http://localhost:5000';
const VAULT_API_URL = `${VAULT_URL}/api/vault`;

export const refreshTokensApi = async (refreshToken: string) => {
  const body = new URLSearchParams();

  body.append('client_id', KEYCLOAK_CLIENT);
  body.append('grant_type', 'refresh_token');
  body.append('refresh_token', refreshToken);

  const res = await authApiInstance.post('token', body);

  return res;
};

export const validateTokenApi = async (
  serviceId: string,
  accountId: string,
) => {
  const access_token = localStorage.getItem(Token.ACCESS_TOKEN);
  const res = await axios.post(`${VAULT_API_URL}/auth/validate_token/${accountId}/${serviceId}`, {}, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });

  return res;
};

export const getLoggedUserApi = async (): Promise<IUser> => {
  const access_token = localStorage.getItem(Token.ACCESS_TOKEN);
  const res = await axios.get(`${VAULT_API_URL}/auth/me`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });

  return res.data;
};

export const logoutApi = async () => {
  const body = new URLSearchParams();

  const refreshToken = localStorage.getItem(Token.REFRESH_TOKEN);

  body.append('client_id', KEYCLOAK_CLIENT);
  body.append('refresh_token', refreshToken || '');

  const res = await authApiInstance.post('logout', body);

  return res;
};
