import { useSelector } from 'react-redux';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Dialog, DialogContent, DialogTitle, DialogActions,
} from '@mui/material';

import { colorThemeSelector, confirmModalSelector } from '../../../store/app/appSelectors';

import './ConfirmModal.scss';

export const ConfirmModal = () => {
  const confirmModal = useSelector(confirmModalSelector);
  const colorTheme = useSelector(colorThemeSelector);

  if (!confirmModal) {
    return <></>;
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={!!confirmModal}
      onClose={confirmModal?.onCancel}
      className={`confirm-modal ${colorTheme}`}
    >
      <DialogTitle
        display="flex"
        alignItems="center"
      >
        <FontAwesomeIcon icon={faCircleQuestion} className="confirm-modal__icon" />
        {confirmModal?.title ?? ''}
      </DialogTitle>
      <DialogContent>
        {confirmModal?.content}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={confirmModal?.onCancel}
          className="confirm-modal__cancel"
        >
          {confirmModal?.cancelText || 'Cancel'}
        </Button>
        <Button
          variant="contained"
          onClick={confirmModal?.onAgree}
          className="confirm-modal__submit"
        >
          {confirmModal?.okText || 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
