import { RootStateType } from '../store';

export const isShowPlugInModal = (state: RootStateType) => {
  return state.plugInReducer.isShowPlugInModal;
};

export const plugInsSelector = (state: RootStateType) => {
  return state.plugInReducer.plugIns;
};

export const plugInSelector = (state: RootStateType) => {
  return state.plugInReducer.plugIn;
};

export const triggerPlugInsSelector = (state: RootStateType) => {
  return state.plugInReducer.triggerPlugIns;
};

export const isPlugInsLoadingSelector = (state: RootStateType) => {
  return state.plugInReducer.isPlugInsLoading;
};

export const isPlugInsUpdatingSelector = (state: RootStateType) => {
  return state.plugInReducer.isPlugInsUpdating;
};

export const plugInStoreSelector = (state: RootStateType) => {
  return state.plugInReducer.plugInStore;
};

export const activePlugInSelector = (state: RootStateType) => {
  return state.plugInReducer.activePlugIn;
};

export const triggerPlugInStoreSelector = (state: RootStateType) => {
  return state.plugInReducer.triggerPlugInStore;
};

export const isPlugInStoreLoadingSelector = (state: RootStateType) => {
  return state.plugInReducer.isPlugInStoreLoading;
};

export const isShowUploadPlugInModal = (state: RootStateType) => {
  return state.plugInReducer.isShowUploadPlugInModal;
};

export const totalCountSelector = (state: RootStateType) => {
  return state.plugInReducer.totalCount;
};

export const plugInStoreTotalCountSelector = (state: RootStateType) => {
  return state.plugInReducer.plugInStoreTotalCount;
};

export const isShowEndpointsModal = (state: RootStateType) => {
  return state.plugInReducer.isShowEndpointsModal;
};

export const endpointsSelector = (state: RootStateType) => {
  return state.plugInReducer.endpoints;
};

export const endpointsLoadingSelector = (state: RootStateType) => {
  return state.plugInReducer.isEndpointsLoading;
};
