import { IApiError, IError } from 'interfaces/IApiError';

const DEFAULT_ERROR_MESSAGE_KEY = 'DEFAULT_ERROR';

export const convertApiError = (apiError: IApiError): IError => {
  let options;
  if (apiError) {
    options = { target: `${apiError.errorTarget}` };
  }

  return {
    message: apiError.error || DEFAULT_ERROR_MESSAGE_KEY,
    printMessage: !apiError.error,
    options,
  };
};
