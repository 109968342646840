import { ThunkActionType } from 'store/store';
import { Token } from 'enum/Token';
import {
  LOGIN_URL, VAULT_URL, getLoggedUserApi, logoutApi,
} from 'services/api/authApi';
import { handleApiError } from 'store/app/appThunk';
import { toDataURL } from 'utils/getBase64Image';
import { ADMIN_POSTFIX } from 'enum/UserRole';
import {
  resetAuthState, setAvatar, setIsAuth, setServices, setUser,
} from './authReducer';

const SERVICE_TYPE_ID = 2;

export const redirectToLogin = (isHard?: boolean) => {
  const pathName = isHard ? window.location.origin : window.location.href;
  window.location.assign(`${LOGIN_URL}${pathName}`);
};

export const getUserData = (): ThunkActionType => {
  return async (dispatch) => {
    try {
      const access_token = localStorage.getItem(Token.ACCESS_TOKEN);

      if (access_token) {
        const res = await getLoggedUserApi();

        if (res.avatar) {
          toDataURL(`${VAULT_URL}${res.avatar}`, (avatar) => dispatch(setAvatar(avatar)));
        }

        dispatch(setUser(res));

        const services = res.services?.filter(({ serviceTypeId }) => (
          serviceTypeId === SERVICE_TYPE_ID
        )).map((item) => {
          const account = res.accounts.find(({ id }) => id === +item.accountId);
          if (account) {
            return ({
              ...item,
              accountName: account.name,
              logo: account.logo,
              isAdmin: account.isAdmin,
              isServiceAdmin: item.role?.includes(ADMIN_POSTFIX),
              isOwner: account.isOwner,
              accountShortName: account.shortName,
            });
          }
          return item;
        });
        dispatch(setServices(services));
        dispatch(setIsAuth(true));
      } else throw Error();
    } catch (error: any) {
      redirectToLogin();
    }
  };
};

export const resetAuth = (): ThunkActionType => async (dispatch) => {
  dispatch(resetAuthState());
};

export const logoutAccount = (): ThunkActionType => {
  return async (dispatch) => {
    try {
      if (localStorage.getItem(Token.REFRESH_TOKEN)) {
        await logoutApi();

        localStorage.removeItem(Token.ACCESS_TOKEN);
        localStorage.removeItem(Token.REFRESH_TOKEN);
        dispatch(resetAuth());
        redirectToLogin(true);
      }
    } catch (err) {
      dispatch(handleApiError(err));
    }
  };
};
