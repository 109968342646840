import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITrigger, ITriggerDataExtended } from 'interfaces/ITrigger';

export interface ITriggersState {
  triggers: ITrigger[];
  totalCount: number | null;
  isTriggersLoading: boolean;
  triggerTriggers: object;
  triggerDetail: ITriggerDataExtended | null;
}

export const initialState: ITriggersState = {
  triggers: [],
  totalCount: null,
  isTriggersLoading: false,
  triggerTriggers: {},
  triggerDetail: null,
};

export const triggersSlice = createSlice({
  name: 'triggers',
  initialState,
  reducers: {
    setTriggers(state, action: PayloadAction<ITrigger[]>) {
      state.triggers = action.payload;
    },
    setTotalCount(state, action: PayloadAction<null | number>) {
      state.totalCount = action.payload;
    },
    setIsTriggersLoading(state, action: PayloadAction<boolean>) {
      state.isTriggersLoading = action.payload;
    },
    setTriggerDetail(state, action: PayloadAction<ITriggerDataExtended | null>) {
      state.triggerDetail = action.payload;
    },
    setTriggerTriggers(state) {
      state.triggerTriggers = {};
    },
    resetTriggersState: () => initialState,
  },
});

export const {
  setTotalCount,
  setTriggers,
  setIsTriggersLoading,
  setTriggerTriggers,
  setTriggerDetail,
  resetTriggersState,
} = triggersSlice.actions;

export default triggersSlice.reducer;
