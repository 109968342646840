import {
  FC, useEffect, useState, useMemo,
} from 'react';
import {
  Alert,
  Snackbar,
} from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from 'hooks/useTypedDispatch';
import { convertApiError } from 'utils/errorUtil';
import { apiErrorSelector } from 'store/app/appSelectors';
import { setApiError } from 'store/app/appReducer';

const TransitionDown = (props: SlideProps) => {
  return <Slide {...props} direction="down" />;
};

export const ErrorMessage: FC = () => {
  const { t } = useTranslation('errors');
  const dispatch = useTypedDispatch();

  const apiError = useSelector(apiErrorSelector);

  const [state, setState] = useState(false);
  const [message, setMessage] = useState('');

  const convertedError = useMemo(() => {
    if (apiError) {
      return convertApiError(apiError);
    }
    return null;
  }, [apiError]);

  useEffect(() => {
    if (convertedError !== null) {
      const { message, options, printMessage } = convertedError;
      setState(true);
      const m = options ? t(message, options) : t(message);
      setMessage(m as string);

      if (printMessage) {
        // eslint-disable-next-line no-console
        console.error(message);
      }

      dispatch(setApiError(null));
    }
  }, [convertedError, t]);

  const onClose = () => setState(false);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={state}
      autoHideDuration={1500}
      onClose={onClose}
      TransitionComponent={TransitionDown}
    >
      <Alert
        onClose={onClose}
        severity="error"
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
