import { RoutePath } from 'constants/RoutePath';

export const VISITED_PAGE = 'visited_page';

const IGNORE_PREFIXES = [
  'edit-',
  'add-',
  'duplicate-',
  'view-edit',
  'view-details',
  'error',
];

const EXCEPTIONS = [
  'add-plugin',
];

export const getVisitedPageKey = (userId, accountId, serviceId) => (
  `${VISITED_PAGE}_${userId}_${accountId}_${serviceId}`
);

export const saveVisitedPage = (
  pathName: string,
  userId: string,
  accountId: number,
  serviceId: number,
) => {
  const itemKey = getVisitedPageKey(userId, accountId, serviceId);
  const visitedPage = localStorage.getItem(itemKey);

  if (pathName && pathName !== '/' && visitedPage !== pathName) {
    const parts = pathName.split('/');
    let pageToSave;
    let lastIndex = 1;

    const pagePrefix = IGNORE_PREFIXES.find((prefix) => {
      const index = parts.findIndex((part) => part.includes(prefix) && !EXCEPTIONS.includes(part));
      if (index !== -1) {
        lastIndex = index;
      }

      return index !== -1;
    });

    if (pagePrefix) {
      pageToSave = `${parts?.slice(0, lastIndex).join('/')}`;
    } else if (
      pathName.includes(RoutePath.PLUG_INS)
      && !pathName.includes(RoutePath.PLUG_INS_STORE)
    ) {
      pageToSave = `/${parts[1]}/${parts[2]}${RoutePath.PLUG_INS}`;
    } else {
      pageToSave = pathName;
    }

    if (pageToSave) {
      localStorage.setItem(itemKey, pageToSave);
    } else {
      localStorage.removeItem(itemKey);
    }
  }
};
