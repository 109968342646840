import { redirectToLogin } from 'store/auth/authThunk';
import { authHeaders } from 'constants/AuthHeaders';
import { Token } from '../enum/Token';

export const toDataURL = (url: string, callback: any, serviceId?: number, accountId?: number) => {
  const token = localStorage.getItem(Token.ACCESS_TOKEN);
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    if (xhr.status === 401) {
      redirectToLogin();
    } else if (xhr.status === 500 || xhr.status === 403) {
      callback('');
    } else {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    }
  };
  xhr.open('GET', url);
  xhr.setRequestHeader('Authorization', `Bearer ${token}`);
  if (serviceId) {
    xhr.setRequestHeader(authHeaders.service, `${serviceId}`);
  }
  if (accountId) {
    xhr.setRequestHeader(authHeaders.account, `${accountId}`);
  }
  xhr.responseType = 'blob';
  xhr.send();
};
