import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  IAccount, IAccountService, IService, IUser,
} from 'interfaces/IUser';

export interface IAuthState {
  user: IUser | null;
  isAuth: boolean;
  avatar: string | null;
  services: IAccountService[] | null;
  service: IService | null;
  account: IAccount | null;
  isNotFound: boolean;
  isServiceAdmin: boolean;
}

export const initialState: IAuthState = {
  user: null,
  isAuth: false,
  avatar: null,
  services: null,
  service: null,
  account: null,
  isNotFound: false,
  isServiceAdmin: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<IUser | null>) {
      state.user = action.payload;
    },
    setIsAuth(state, action: PayloadAction<boolean>) {
      state.isAuth = action.payload;
    },
    setAvatar(state, action: PayloadAction<string | null>) {
      state.avatar = action.payload;
    },
    setServices(state, action: PayloadAction<IAccountService[] | null>) {
      state.services = action.payload;
    },
    setService(state, action: PayloadAction<IService | null>) {
      state.service = action.payload;
    },
    setAccount(state, action: PayloadAction<IAccount | null>) {
      state.account = action.payload;
    },
    setIsNotFound(state, action: PayloadAction<boolean>) {
      state.isNotFound = action.payload;
    },
    setIsServiceAdmin(state, action: PayloadAction<boolean>) {
      state.isServiceAdmin = action.payload;
    },
    resetAuthState: () => initialState,
  },
});

export const {
  setUser,
  resetAuthState,
  setIsAuth,
  setAvatar,
  setServices,
  setService,
  setAccount,
  setIsNotFound,
  setIsServiceAdmin,
} = authSlice.actions;

export default authSlice.reducer;
