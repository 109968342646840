import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEndpoints, IPlugIn, IPlugInStore } from '../../interfaces/IPlugIn';

export interface IActivePlugInModal {
  isActive: boolean;
  _id: string;
}

export interface IActiveEndpointsModal {
  isActive: boolean;
  instanceId: string;
}

export interface IPluInState {
  isShowPlugInModal: IActivePlugInModal;
  plugIns: IPlugIn[];
  isPlugInsLoading: boolean;
  triggerPlugIns: object;
  plugInStore: IPlugInStore[];
  isPlugInStoreLoading: boolean;
  isPlugInsUpdating: boolean;
  triggerPlugInStore: object;
  activePlugIn: IPlugInStore;
  isShowUploadPlugInModal: boolean;
  totalCount: number | null;
  plugIn: IPlugIn | null;
  plugInStoreTotalCount: number | null;
  isShowEndpointsModal: IActiveEndpointsModal;
  isEndpointsLoading: boolean;
  endpoints: IEndpoints[];
}

export const initialState: IPluInState = {
  isShowPlugInModal: { isActive: false, _id: '' },
  plugIns: [],
  isPlugInsLoading: false,
  triggerPlugIns: {},
  plugInStore: [],
  isPlugInStoreLoading: false,
  isPlugInsUpdating: false,
  triggerPlugInStore: {},
  activePlugIn: {} as IPlugInStore,
  plugIn: null,
  isShowUploadPlugInModal: false,
  totalCount: null,
  plugInStoreTotalCount: null,
  isShowEndpointsModal: { isActive: false, instanceId: '' },
  isEndpointsLoading: false,
  endpoints: [],
};

export const plugInSlice = createSlice({
  name: 'plugIn',
  initialState,
  reducers: {
    setIsShowPlugInModal(state, action: PayloadAction<IActivePlugInModal>) {
      state.isShowPlugInModal = action.payload;
    },
    setPlugIns(state, action: PayloadAction<IPlugIn[]>) {
      state.plugIns = action.payload;
    },
    setPlugIn(state, action: PayloadAction<IPlugIn>) {
      state.plugIn = action.payload;
    },
    setIsPlugInsLoading(state, action: PayloadAction<boolean>) {
      state.isPlugInsLoading = action.payload;
    },
    setIsPlugInsUpdating(state, action: PayloadAction<boolean>) {
      state.isPlugInsUpdating = action.payload;
    },
    setTriggerPlugIns(state) {
      state.triggerPlugIns = {};
    },
    setPlugInStore(state, action: PayloadAction<IPlugInStore[]>) {
      state.plugInStore = action.payload;
    },
    setIsPlugInStoreLoading(state, action: PayloadAction<boolean>) {
      state.isPlugInStoreLoading = action.payload;
    },
    setTriggerPlugInStore(state) {
      state.triggerPlugInStore = {};
    },
    setActivePlugIn(state, action: PayloadAction<IPlugInStore>) {
      state.activePlugIn = action.payload;
    },
    setIsShowUploadPlugInModal(state, action: PayloadAction<boolean>) {
      state.isShowUploadPlugInModal = action.payload;
    },
    setTotalCount(state, action: PayloadAction<null | number>) {
      state.totalCount = action.payload;
    },
    setPlugInStoreTotalCount(state, action: PayloadAction<null | number>) {
      state.plugInStoreTotalCount = action.payload;
    },
    setIsShowEndpointsModal(state, action: PayloadAction<IActiveEndpointsModal>) {
      state.isShowEndpointsModal = action.payload;
    },
    setIsEndpointsLoading(state, action: PayloadAction<boolean>) {
      state.isEndpointsLoading = action.payload;
    },
    setEndpoints(state, action: PayloadAction<IEndpoints[]>) {
      state.endpoints = action.payload;
    },
    resetPlugInsState: () => initialState,
  },
});

export const {
  setIsShowPlugInModal,
  setPlugIns,
  setIsPlugInsLoading,
  setIsPlugInsUpdating,
  setTriggerPlugIns,
  setPlugInStore,
  setIsPlugInStoreLoading,
  setTriggerPlugInStore,
  setActivePlugIn,
  setIsShowUploadPlugInModal,
  setTotalCount,
  setPlugIn,
  setPlugInStoreTotalCount,
  setIsShowEndpointsModal,
  setIsEndpointsLoading,
  setEndpoints,
  resetPlugInsState,
} = plugInSlice.actions;

export default plugInSlice.reducer;
