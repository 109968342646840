import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAction } from 'interfaces/IAction';

export interface IActionsState {
  actions: IAction[];
  totalCount: number | null;
  isActionsLoading: boolean;
  triggerActions: object;
  actionDetail: IAction | null;
}

export const initialState: IActionsState = {
  actions: [],
  totalCount: null,
  isActionsLoading: false,
  triggerActions: {},
  actionDetail: null,
};

export const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    setActions(state, action: PayloadAction<IAction[]>) {
      state.actions = action.payload;
    },
    setTotalCount(state, action: PayloadAction<null | number>) {
      state.totalCount = action.payload;
    },
    setIsActionsLoading(state, action: PayloadAction<boolean>) {
      state.isActionsLoading = action.payload;
    },
    setActionDetail(state, action: PayloadAction<IAction | null>) {
      state.actionDetail = action.payload;
    },
    setTriggerActions(state) {
      state.triggerActions = {};
    },
    resetActionsState: () => initialState,
  },
});

export const {
  setTotalCount,
  setActions,
  setIsActionsLoading,
  setTriggerActions,
  setActionDetail,
  resetActionsState,
} = actionsSlice.actions;

export default actionsSlice.reducer;
