import { Box } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AppAvatar from 'components/shared/AppAvatar/AppAvatar';

import './List.scss';

interface IListItem {
  name: string,
  accountShortName?: string,
  accountName?: string,
  logo?: string | null,
  shortName: string,
  isAdmin?: boolean,
  onClick: () => void,
}

const ListMenuItem: FC<IListItem> = ({
  name,
  logo,
  shortName,
  isAdmin = false,
  onClick,
  accountName,
  accountShortName,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    navigate(`${accountShortName}/${shortName}`);
    onClick();
  }, [shortName, accountShortName]);

  const role = useMemo(() => (isAdmin ? t('admin') : t('member')), [isAdmin]);

  return (
    <Box className="list-item menu" onClick={handleClick}>
      <AppAvatar path={logo ?? ''} />
      <Box className="main">
        <Box className="text">
          <Box className="name">{name}</Box>
          <Box className="account">{accountName}</Box>
          <Box className="role">{role}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ListMenuItem;
