import {
  useState, MouseEvent, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  IconButton,
  Typography,
  Popover,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faStar } from '@fortawesome/pro-solid-svg-icons';
import { faBell, faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

import { appHeaderSelector, colorThemeSelector } from 'store/app/appSelectors';
import Breadcrumb, { IBreadCrumbItem } from './components/Breadcrumb/Breadcrumb';
import AccountMenu from './components/AccountMenu/AccountMenu';
import NavigationMobilePane from '../NavigationPane/NavigationMobilePane';
import BurgerMenu from './components/BurgerMenu/BurgerMenu';
import { MAXRATING } from '../../layouts/PlugIns/AddPlugInsPage/PlugInCard/PlugInCard';

import { ReactComponent as Logo } from '../../../assets/logo.svg';

import './AppHeader.scss';

export interface IAppHeader {
  title: string;
  menuItems?: IBreadCrumbItem[],
  description?: string;
  rating?: number;
}

const AppHeader = () => {
  const data = useSelector(appHeaderSelector);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const theme = useSelector(colorThemeSelector);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openMenu, setOpenMenu] = useState(false);

  const changeMenuActivity = useCallback(() => {
    setOpenMenu(!openMenu);
  }, [openMenu, setOpenMenu]);

  return (
    <Box className={`header ${theme}`}>
      <Box className="mobile-header">
        <Box className="mobile-header_wrapper">
          <Logo />
          <BurgerMenu open={openMenu} onClose={changeMenuActivity} />
        </Box>
        <Box className={`mobile-header_menu ${openMenu ? 'open' : 'close'}`}>
          <NavigationMobilePane />
          <Box className="header__header-buttons account-mobile-menu">
            <Box>
              <IconButton aria-label="notifications" className="header__header-buttons-item">
                <FontAwesomeIcon icon={faBell} />
              </IconButton>
              <span>{t('links.notifications')}</span>
            </Box>
            <Box>
              <IconButton aria-label="support" className="header__header-buttons-item">
                <FontAwesomeIcon icon={faCircleQuestion} />
              </IconButton>
              <span>{t('links.support')}</span>
            </Box>
            <AccountMenu />
          </Box>
        </Box>
      </Box>
      <Box className="header__header">
        {!!data?.menuItems && <Breadcrumb items={data.menuItems} />}
        <Box className="header__header-buttons">
          <IconButton aria-label="notifications" className="header__header-buttons-item">
            <FontAwesomeIcon icon={faBell} />
          </IconButton>
          <IconButton aria-label="support" className="header__header-buttons-item">
            <FontAwesomeIcon icon={faCircleQuestion} />
          </IconButton>
          <AccountMenu />
        </Box>
      </Box>
      <Box className="header__main">
        <Typography component="h1">
          {data.title}
        </Typography>
        {data.description && (
          <>
            <IconButton onClick={handleClick}>
              <FontAwesomeIcon icon={faCircleInfo} className="info" />
            </IconButton>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography sx={{ p: 2 }}>{data.description}</Typography>
            </Popover>
          </>
        )}
        {data.rating && (
          <span className="plug-in-rating">
            <FontAwesomeIcon icon={faStar} />
            {`${data.rating} / ${MAXRATING}`}
          </span>
        )}
      </Box>
    </Box>
  );
};

export default AppHeader;
