import { RootStateType } from '../store';

export const appHeaderSelector = (state: RootStateType) => {
  return state.appReducer.appHeader;
};

export const colorThemeSelector = (state: RootStateType) => {
  return state.appReducer.colorTheme;
};

export const isAppLoaderelector = (state: RootStateType) => {
  return state.appReducer.isAppLoader;
};

export const apiErrorSelector = (state: RootStateType) => {
  return state.appReducer.apiError;
};

export const confirmModalSelector = (state: RootStateType) => {
  return state.appReducer.confirmModal;
};

export const isNavDisabledSelector = (state: RootStateType) => {
  return state.appReducer.isNavDisabled;
};
