import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAppHeader } from 'components/shared/AppHeader/AppHeader';
import { ColorTheme } from 'enum/ColorTheme';
import { IApiError } from 'interfaces/IApiError';
import { IConfirmModal } from 'utils/callConfirmModal';

export interface IAccountState {
  appHeader: IAppHeader;
  colorTheme: ColorTheme,
  isAppLoader: boolean;
  confirmModal: IConfirmModal | null;
  apiError: IApiError | null;
  isNavDisabled: boolean;
}

export const initialState: IAccountState = {
  appHeader: {
    title: '',
  },
  colorTheme: ColorTheme.BLUE,
  isAppLoader: true,
  confirmModal: null,
  apiError: null,
  isNavDisabled: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppHeader(state, action: PayloadAction<IAppHeader>) {
      state.appHeader = action.payload;
    },
    setColorTheme(state, action: PayloadAction<ColorTheme>) {
      state.colorTheme = action.payload;
    },
    setIsAppLoader(state, action: PayloadAction<boolean>) {
      state.isAppLoader = action.payload;
    },
    setConfirmModal(state, action: PayloadAction<IConfirmModal | null>) {
      state.confirmModal = action.payload;
    },
    setApiError(state, action: PayloadAction<IApiError | null>) {
      state.apiError = action.payload;
    },
    setIsNavDisabled(state, action: PayloadAction<boolean>) {
      state.isNavDisabled = action.payload;
    },
    resetAppHeaderState(state) {
      state.appHeader = initialState.appHeader;
    },
  },
});

export const {
  setAppHeader,
  setColorTheme,
  resetAppHeaderState,
  setIsAppLoader,
  setConfirmModal,
  setApiError,
  setIsNavDisabled,
} = appSlice.actions;

export default appSlice.reducer;
