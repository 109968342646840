import { FC, ReactNode, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import useMuiTheme from 'hooks/useMuiTheme';
import { AppLoader } from '../AppLoader/AppLoader';

interface IComponentProps {
  store: any;
  children: ReactNode;
}

const ProviderWrapper: FC<IComponentProps> = ({
  store,
  children,
}) => {
  const theme = useMuiTheme();

  return (
    <Suspense fallback={<AppLoader />}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <BrowserRouter>
              {children}
            </BrowserRouter>
          </Provider>
        </ThemeProvider>
      </LocalizationProvider>
    </Suspense>
  );
};

export default ProviderWrapper;
