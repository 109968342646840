import apiInstance from './instances/apiInstances';
import {
  IEndpoints,
  IPlugIn,
  IPlugInDataResponse,
  IPlugInDeleteResponse,
  IPlugInsRequest,
  IPlugInsResponse, IPlugInStopResponse, IPlugInStoreData,
  IPlugInStoreRequest,
  IStoreResponse,
} from '../../interfaces/IPlugIn';

export const getPlugInsApi = async (data: IPlugInsRequest): Promise<IPlugInsResponse> => {
  const res = await apiInstance.get('/plug_ins', {
    params: {
      ...data,
    },
  });

  return res.data;
};

export const getPlugInApi = async (name: string, version: string): Promise<IPlugInDataResponse> => {
  const res = await apiInstance.get(`/plug_ins/${name}/${version}`);

  return res.data;
};

export const getPlugInByIdApi = async (plugInId: string): Promise<IPlugIn> => {
  const res = await apiInstance.get(`/plug_ins/${plugInId}`);

  return res.data;
};

export const getPlugInStoreApi = async (data: IPlugInStoreRequest): Promise<IStoreResponse> => {
  const res = await apiInstance.get('/plug_in_store', {
    params: {
      ...data,
    },
  });

  return res.data;
};

export const removePlugIn = async (name: string, version: string):
    Promise<IPlugInDeleteResponse> => {
  const res = await apiInstance.delete(`/plug_ins/${name}/${version}`);

  return res.data;
};

export const startPlugInApi = async (data: any, name: string, version: string):
    Promise<IPlugInStoreData> => {
  const res = await apiInstance.post(`/plug_ins/${name}/${version}`, {
    ...data,
  });

  return res.data;
};
export const startPlugInInstanceApi = async (name: string, version: string, _id: string):
    Promise<IPlugInStopResponse> => {
  const res = await apiInstance.put(`/plug_ins/enable/${name}/${version}/${_id}`);

  return res.data;
};

export const updateInstanceSettings = async (name: string, version: string, _id: string, data: any):
  Promise<IPlugInStoreData> => {
  const res = await apiInstance.put(`/plug_ins/${name}/${version}/${_id}`, {
    ...data,
  });

  return res.data;
};

export const stopPlugInInstance = async (name: string, version: string, _id: string):
    Promise<IPlugInStopResponse> => {
  const res = await apiInstance.put(`/plug_ins/disable/${name}/${version}/${_id}`);

  return res.data;
};

export const deletePlugInInstance = async (name: string, version: string, _id: string):
    Promise<IPlugInStopResponse> => {
  const res = await apiInstance.delete(`/plug_ins/${name}/${version}/${_id}`);

  return res.data;
};

export const uploadPlugInApi = async (plugIn: FormData):
    Promise<IPlugInStoreData> => {
  const res = await apiInstance.post('/plug_in_store', plugIn);

  return res.data;
};

export const getEndpointsApi = async (plugInId: string, plugInInstanceId: string):
  Promise<IEndpoints[]> => {
  const res = await apiInstance.get(`/plug_ins/endpoints/${plugInId}/${plugInInstanceId}`);
  return res.data;
};
