import {
  FC, ReactNode, useCallback, useState, MouseEvent,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogProps,
  Button,
  ButtonProps,
  Breakpoint,
  IconButton,
  Popover,
  Typography,
  Box,
} from '@mui/material';
import { faCircleInfo, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colorThemeSelector } from 'store/app/appSelectors';

import './AppModal.scss';

interface IComponentProps extends DialogProps {
  title?: string,
  open: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
  isLoadingDataInModal?: boolean;
  propsSubmitButton?: ButtonProps;
  titleSubmitButton?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  maxWidth?: Breakpoint | false;
  hideSubmitButton?: boolean;
  error?: string;
  className?: string;
  hideInfo?: boolean;
}

const AppModal: FC<IComponentProps> = ({
  open,
  title,
  isLoadingDataInModal = false,
  propsSubmitButton,
  onSubmit,
  onCancel,
  titleSubmitButton = 'Submit',
  children,
  footer,
  error,
  className,
  maxWidth = 'md',
  hideSubmitButton = false,
  hideInfo = false,
  ...otherProps
}) => {
  const theme = useSelector(colorThemeSelector);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openTooltip = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmitAction = useCallback(() => {
    if (onSubmit) {
      onSubmit();
    }
  }, [onSubmit]);

  const onCancelAction = useCallback(() => {
    if (!isLoadingDataInModal && onCancel) {
      onCancel();
    }
  }, [isLoadingDataInModal, onCancel]);

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      className={`app-modal ${theme} ${className}`}
      open={open}
      onClose={onCancelAction}
      {...otherProps}
    >
      <FontAwesomeIcon
        icon={faXmark}
        className="close-icon"
        onClick={onCancelAction}
      />
      {title
        && (
        <DialogTitle>
          {title}
          {!hideInfo
          && (
          <>
            <IconButton onClick={handleClick}>
              <FontAwesomeIcon icon={faCircleInfo} className="info" />
            </IconButton>
            <Popover
              open={openTooltip}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography sx={{ p: 2 }}>{title}</Typography>
            </Popover>
          </>
          )}
        </DialogTitle>
        )}
      <DialogContent>
        {children}
      </DialogContent>
      {
        !hideSubmitButton && (
          <DialogActions>
            {
              footer || (
                <Box className="app-modal footer">
                  <Box className="app-modal error">{error || ''}</Box>
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={onSubmitAction}
                    {...propsSubmitButton}
                  >
                    {titleSubmitButton}
                  </Button>
                </Box>
              )
            }
          </DialogActions>
        )
      }
    </Dialog>
  );
};

export default AppModal;
