import { useLocation } from 'react-router-dom';

import Links from './Links/Links';
import pagesLinks from './PagesLinks';

import './NavigationPane.scss';

const NavigationMobilePane = () => {
  const { pathname } = useLocation();

  return (
    <Links links={pagesLinks()} pathName={pathname} />
  );
};

export default NavigationMobilePane;
