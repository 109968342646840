import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { toDataURL } from 'utils/getBase64Image';
import { serviceSelector } from 'store/auth/authSelectors';

import './AppIcon.scss';

interface IComponentProps {
  path: string;
  alt?: string;
  className?: string;
}

const AppIcon: FC<IComponentProps> = ({
  path,
  alt = '',
  className = '',
}) => {
  const service = useSelector(serviceSelector);
  const [avatar, setAvatar] = useState<string | undefined>();

  useEffect(() => {
    if (path) {
      toDataURL(path, setAvatar, service?.id, service?.accountId);
    } else {
      setAvatar('');
    }
  }, [path, service]);

  return (
    <div className={`image ${className}`}>
      {avatar && <img src={avatar} alt={alt} />}
    </div>
  );
};

export default AppIcon;
