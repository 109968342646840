import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFilterCount } from 'components/shared/AppTable/components/TableFilters/TableFilters';
import { IBaseFlow } from '../../interfaces/IFlow';

export interface IFlowState {
  triggerFlows: object;
  isFlowsLoading: boolean;
  filters: IFilterCount | null;
  totalCount: number | null;
  flows: IBaseFlow[];
  flowDetail: IBaseFlow | null;
}

export const initialState: IFlowState = {
  triggerFlows: {},
  isFlowsLoading: false,
  filters: null,
  totalCount: null,
  flows: [],
  flowDetail: null,
};

export const flowSlice = createSlice({
  name: 'flow',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<IFilterCount | null>) {
      state.filters = action.payload;
    },
    setTriggerFlows(state) {
      state.triggerFlows = {};
    },
    setIsFlowsLoading(state, action: PayloadAction<boolean>) {
      state.isFlowsLoading = action.payload;
    },
    setTotalCount(state, action: PayloadAction<null | number>) {
      state.totalCount = action.payload;
    },
    setFlows(state, action: PayloadAction<IBaseFlow[]>) {
      state.flows = action.payload;
    },
    setFlowDetail(state, action: PayloadAction<IBaseFlow | null>) {
      state.flowDetail = action.payload;
    },
    resetFlowsState: () => initialState,
  },
});

export const {
  setFlows,
  setFilters,
  setIsFlowsLoading,
  setTriggerFlows,
  setTotalCount,
  setFlowDetail,
  resetFlowsState,
} = flowSlice.actions;

export default flowSlice.reducer;
