import axios from 'axios';

import { isProduction } from 'utils/isProd';
import { requestInterceptors } from '../interceptors/requestInterceptors';
import { responseInterceptors } from '../interceptors/responseInterceptors';

const REACT_APP_KEYCLOAK_PROXY_ENDPOINT = isProduction() ? process.env.REACT_APP_VAULT_KEYCLOAK : 'http://localhost:8080';

const authApiInstance = axios.create({
  baseURL: `${REACT_APP_KEYCLOAK_PROXY_ENDPOINT}/auth/realms/${process.env.REACT_APP_REALM}/protocol/openid-connect/`,
});

requestInterceptors(authApiInstance);

responseInterceptors(authApiInstance);

export default authApiInstance;
