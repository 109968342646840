import appReducer from './app/appReducer';
import objectTypesReducer from './objectTypes/objectTypesReducer';
import transformerReducer from './transformer/transformerReducer';
import plugInReducer from './plugin/pluginReducer';
import authReducer from './auth/authReducer';
import viewsReducer from './views/viewsReducer';
import triggersReducer from './triggers/triggersReducer';
import actionsReducer from './actions/actionsReducer';
import flowsReducer from './flows/flowsReducer';

export const rootReducer = {
  appReducer,
  objectTypesReducer,
  transformerReducer,
  plugInReducer,
  authReducer,
  viewsReducer,
  triggersReducer,
  actionsReducer,
  flowsReducer,
};
