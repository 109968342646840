import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { rootReducer } from './rootReducer';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['app/setConfirmModal'],
      ignoredPaths: ['appReducer.confirmModal.onAgree', 'appReducer.confirmModal.onCancel'],
    },
  }),
});

export type AppDispatchType = typeof store.dispatch;
export type StoreType = typeof store;
export type RootStateType = ReturnType<typeof store.getState>;
export type ThunkActionType = ThunkAction<void, RootStateType, unknown, AnyAction>;
