import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-solid-svg-icons';

import AppIcon from 'components/shared/AppIcon/AppIcon';
import { getPlugInIconPath } from 'utils/getPlugInIconPath';
import { RoutePath } from '../../../../../constants/RoutePath';
import { useTypedDispatch } from '../../../../../hooks/useTypedDispatch';
import { setIsShowPlugInModal, setActivePlugIn } from '../../../../../store/plugin/pluginReducer';
import PlugInModalDescription from '../../PluginModalDescription/PluginModalDescription';
import { IPlugInStore } from '../../../../../interfaces/IPlugIn';
import { deletePlugIn } from '../../../../../store/plugin/pluginThunk';
import { dateBySlash } from '../../../../../utils/dateBySlash';
import { callConfirmModal } from '../../../../../utils/callConfirmModal';

import './PlugInCard.scss';

export const MAXRATING = 5;

const PlugInCard: FC<IPlugInStore> = (
  {
    _id, author, version, name, label, description,
    allowMultipleInstances, installed, creationDate, modificationDate,
    preferences, rating, numOfInstances, icon,
  },
) => {
  const { t } = useTranslation(['translation', 'plugIn']);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const handleInstall = useCallback(() => {
    dispatch(setActivePlugIn({
      _id,
      author,
      version,
      name,
      label,
      description,
      allowMultipleInstances,
      installed,
      creationDate,
      modificationDate,
      preferences,
      rating,
      numOfInstances,
    }));
    navigate(`../..${RoutePath.PLUG_INS}/${name}/${version}`, { relative: 'path' });
  }, []);

  const handleUnInstall = useCallback(() => {
    callConfirmModal(
      {
        title: t('plugIn:confirmUninstall', { label }),
        onAgree() {
          dispatch(deletePlugIn(name, version));
        },
        okText: t('plugIn:buttons.unInstallPlugIn'),
        cancelText: t('translation:buttons.cancel'),
      },
      dispatch,
    );
  }, []);

  const handleModalOpen = useCallback(() => {
    dispatch(setIsShowPlugInModal({ isActive: true, _id }));
  }, [_id]);

  const installedMessage = useMemo(() => {
    if (allowMultipleInstances && installed) {
      return numOfInstances === 1 ? t('plugIn:installedOneTime', { numOfInstances }) : t('plugIn:installedTimes', { numOfInstances });
    }
    return (!allowMultipleInstances && installed) ? t('plugIn:alreadyInstalled') : '';
  }, [allowMultipleInstances, numOfInstances, installed]);

  return (
    <Box key={_id} className={classNames('plug-in-card', { 'installed-plug-in-card': installed })}>
      <div className="plug-in-card-header">
        <AppIcon path={icon ? getPlugInIconPath(name, version, installed) : ''} alt={label} className="plug-in-card-logo" />
        <Box>
          <div className="card-name">{label}</div>
          {/* hide this part for the demo
          <div className="card-author">
            {t('plugIn:createdByAuthor', { author })}
          </div>
          */}
          <div className="plug-in-card-last-update">
            {`${t('plugIn:lastUpdated')}: ${dateBySlash(modificationDate)}`}
          </div>
        </Box>
      </div>
      <div className="card-short-description">{description}</div>
      <div className="footer-wrapper-for-plug-in-card">
        {rating ? (
          <span className="plug-in-rating">
            <FontAwesomeIcon icon={faStar} />
            {`${rating}/${MAXRATING}`}
          </span>
        ) : (<span />)}
        <span>
          <Button
            variant="contained"
            type="submit"
            className="plug-in-card-more-details"
            onClick={handleModalOpen}
          >
            {t('plugIn:moreDetails')}
          </Button>
          <Button
            className="plugin-btn"
            variant="outlined"
            sx={{
              color: 'black',
              mr: 1,
              borderRadius: 50,
            }}
            disabled={installed && !allowMultipleInstances}
            onClick={() => handleInstall()}
          >
            {t('plugIn:buttons.installPlugIn')}
          </Button>
          {installed && (
            <Button
              className="plugin-btn"
              variant="outlined"
              sx={{
                color: 'black',
                mr: 1,
                borderRadius: 50,
              }}
              onClick={() => handleUnInstall()}
            >
              {allowMultipleInstances ? t('plugIn:buttons.unInstallAllPlugIn') : t('plugIn:buttons.unInstallPlugIn')}
            </Button>
          )}
        </span>
      </div>
      <div className="plug-in-install-info">
        {installedMessage}
      </div>
      <PlugInModalDescription
        _id={_id}
        author={author}
        version={version}
        name={name}
        label={label}
        description={description}
        allowMultipleInstances={allowMultipleInstances}
        installed={installed}
        creationDate={creationDate}
        modificationDate={modificationDate}
        preferences={preferences}
        rating={rating}
        numOfInstances={numOfInstances}
        icon={icon}
      />
    </Box>
  );
};

export default PlugInCard;
