import { ReactNode } from 'react';

import { setConfirmModal } from '../store/app/appReducer';
import { AppDispatchType } from '../store/store';

export interface IConfirmModal {
  title: ReactNode;
  content?: ReactNode;
  onAgree?: () => void;
  onCancel?: () => void;
  okText?: ReactNode;
  cancelText?: ReactNode;
}

export const callConfirmModal = (modal: IConfirmModal, dispatch: AppDispatchType) => {
  const {
    title,
    content,
    okText,
    cancelText,
    onCancel,
    onAgree,
  } = modal;

  const payload = {
    title,
    content,
    okText,
    cancelText,
    onAgree() {
      if (onAgree) {
        onAgree();
      }
      dispatch(setConfirmModal(null));
    },
    onCancel() {
      if (onCancel) {
        onCancel();
      }
      dispatch(setConfirmModal(null));
    },
  };

  dispatch(setConfirmModal(payload));
};
